const dataParser = (data) => {
  const parser = {
    1101: {
      results: data.last.winningNumbers.list,
      eventTime: data.last.drawTime,
      drawSumEvenOdd: data.last.drawSumEvenOdd,
      drawSumRange: data.last.drawSumRange,
      drawSumTotal: data.last.drawSumTotal,
      nextEventTime: data.active.drawTime,
      categoryData: data.active.active.category,
      tournamentData: data.active.active.tournament,
    },
  };
  return parser[data.tournamentId];
};

export default dataParser;
