import getConfigService from './getConfigService';

const getConfig = async (data, narData = null) => {
  try {
    let { configData, layout } = data;
    if (configData.application === 'retail' || narData) {
      const allData = { ...configData, ...narData };
      layout = allData.layout || layout;
      configData = await getConfigService(allData);
    }
    return configData ? { ...configData, layout } : null;
  } catch (e) {
    return null;
  }
};

export default getConfig;
