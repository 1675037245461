export default {
  SET_APP_STARTED: 'SET_APP_STARTED',
  SET_CONFIG: 'SET_CONFIG',
  SET_RESULTS: 'SET_RESULTS',
  SET_ACTIVE_LOADER: 'SET_ACTIVE_LOADER',
  SET_STATISTICS: 'SET_STATISTICS',
  SET_SCREEN_SIZE: 'SET_SCREEN_SIZE',
  SET_CATEGORY_CONFIG: 'SET_CATEGORY_CONFIG',
  SET_DRAWN_RESULTS: 'SET_DRAWN_RESULTS',
  UPDATE_DRAWN_RESULTS: 'UPDATE_DRAWN_RESULTS',
  SET_NEW_DRAWN_COUNTER: 'SET_NEW_DRAWN_COUNTER',
  SET_IS_RESULTS_DATA_VALID: 'SET_IS_RESULTS_DATA_VALID',
  ADD_EVENTS: 'ADD_EVENTS',
  SET_ACTIVE_COUNTER: 'SET_ACTIVE_COUNTER',
  SET_DRAWN_IN_PROGRESS: 'SET_DRAWN_IN_PROGRESS',
  ADD_MAIN_EVENTS: 'ADD_MAIN_EVENTS',
  ADD_OTHERS_BETTING_OFFER: 'ADD_OTHERS_BETTING_OFFER',
  SET_DONT_HAVE_INTERNET_TIME: 'SET_DONT_HAVE_INTERNET_TIME',
  SET_RETAIL_DRAW_EVENT: 'SET_RETAIL_DRAW_EVENT',
  PREPARE_RETAIL_DRAW_EVENT: 'PREPARE_RETAIL_DRAW_EVENT',
  SET_APP_AVAILABLE: 'SET_APP_AVAILABLE',
  SET_CONNECTION_ERROR: 'SET_CONNECTION_ERROR',
  SET_VISUALIZATION_DRAW_ENABLED: 'SET_VISUALIZATION_DRAW_ENABLED',
  RESET_DRAW_NEXT_EVENT: 'RESET_DRAW_NEXT_EVENT',
  SET_FETCH_EVENTS_ERROR: 'SET_FETCH_EVENTS_ERROR',
};
