import {
  addSeconds,
  getTime,
  format,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const formatDate = (date, pattern, timeZone) => {
  const zoneTime = utcToZonedTime(date, timeZone);
  return format(zoneTime, pattern);
};

export {
  addSeconds,
  getTime,
  formatDate,
};
