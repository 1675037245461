const config = {
  1101: {
    api: '/api/results?tournamentId=49024',
    id: 1100,
    colors: {
      primary: '#1565C0',
      secondary: '#1565C0',
      third: '#1565C0',
    },
    statistics: {
      api: '/api?tournamentId=49024',
    },
  },
};

const apiConfig = (name) => config[name] || null;

export default apiConfig;
