import { addSeconds, getTime } from '@/utility/dateFns';

const calcDrawnNumbers = (eventTime, maxBalls, haveResults) => {
  let numbersDrawn = 100;
  if (haveResults) {
    const eventTimestampPlus = getTime(addSeconds(eventTime, 25));
    const tempTimestamp = new Date().getTime();
    const seconds = (tempTimestamp - eventTimestampPlus) / 1000;
    const tempDrawResults = (parseInt(seconds.toString(), 10) / maxBalls).toString();
    numbersDrawn = parseInt(tempDrawResults, 10);
  }
  return numbersDrawn;
};

export default calcDrawnNumbers;
