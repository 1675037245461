<template>
  <div class="load-spinner">
    <div class="spinner-wrapper">
      <div id="app-spinner"
           class="spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
  props: ['theme'],
};
</script>

<style lang="scss" scoped>
  .load-spinner {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9999;
    .spinner {
      animation: ls-spinner 1s infinite linear;
      border: 2px solid #e6e6e6;
      border-left-color: rgba(0, 0, 0, .2);
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
  }
  @keyframes ls-spinner {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
</style>
