import { chunk, replace } from 'lodash';

export default {
  config: (state) => state.config,
  layout: (state) => state.config?.layout,
  category: (state) => state.category,
  tournament: (state) => replace(state.tournament, state.category, ''),
  flag: (state) => state.flag,
  results: (state) => state.results,
  bonus: (state) => state.bonus,
  eventTime: (state) => state.eventTime,
  maxRegular: (state) => state.maxRegular,
  maxBonus: (state) => state.maxBonus,
  drawnResults: (state) => state.drawnResults,
  drawnBonus: (state) => state.drawnBonus,
  nextEventTime: (state) => state.nextEventTime,
  activeLoader: (state) => state.activeLoader,
  appStarted: (state) => state.appStarted,
  mostPlayedNumbers: (state) => state.mostPlayedNumbers,
  leastPlayedNumbers: (state) => state.leastPlayedNumbers,
  mostDrawnNumbers: (state) => state.mostDrawnNumbers,
  leastDrawnNumbers: (state) => state.leastDrawnNumbers,
  screenSize: (state) => state.screenSize,
  categoryConfig: (state) => state.categoryConfig,
  primary: (state) => state.categoryConfig.colors.primary,
  secondary: (state) => state.categoryConfig.colors.secondary,
  third: (state) => state.categoryConfig.colors.third,
  timeZone: (state) => state.config.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  newDrawnCounter: (state) => state.newDrawnCounter,
  translations: (state) => state.translations,
  translate: (state) => (key, value = null) => {
    const translation = state.translations[key] || key;
    return value !== null ? replace(translation, '{value}', value) : translation;
  },
  isResultsDataValid: (state) => state.isResultsDataValid,
  events: (state) => state.events,
  mainEvents: (state) => state.mainEvents,
  bettingOffer: (state) => (part) => chunk(state.bettingOffer, 5)[part],
  intervalTimeForBettingEvents: (state) => state.intervalTimeForBettingEvents,
  resultsSpecials: (state) => state.resultsSpecials,
  retailNextEvent: (state) => state.retailNextEvent,
  transitionBetweenComponentDuration: (state) => state.transitionBetweenComponentDuration,
  activeCounter: (state) => state.activeCounter,
  drawInProgress: (state) => state.drawInProgress,
  dontHaveInternetTime: (state) => state.dontHaveInternetTime,
  retailDrawEvent: (state) => state.retailDrawEvent,
  prepareRetailDrawEvent: (state) => state.prepareRetailDrawEvent,
  appAvailable: (state) => state.appAvailable,
  connectionError: (state) => state.connectionError,
  isVisualizationDrawEnabled: (state) => state.isVisualizationDrawEnabled,
  isRetail: (state) => state.config?.layout === 'RetailLayout',
  fetchEventsError: (state) => state.fetchErrorDraw || state.fetchErrorOthers,
  queryParams: (state) => state.queryParams,
  categoryShortName: (state) => state.categoryData?.shortName || state.category, // draw category
  tournamentShortName: (state, getters) => (state.tournamentData?.shortName
    ? replace(state.tournamentData.shortName, getters.categoryShortName, '')
    : getters.tournament), // draw tournament
};
