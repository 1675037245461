export default {
  config: null,
  category: 'Greece',
  tournament: 'Greece Keno 20/80',
  flag: 'GRC',
  eventTime: null,
  results: null,
  bonus: null,
  maxRegular: 20,
  maxBonus: null,
  drawnResults: [],
  drawnBonus: null,
  tempDrawnNumber: null,
  nextEventTime: null,
  activeLoader: true,
  appStarted: false,
  mostPlayedNumbers: [32, 28, 44, 65],
  leastPlayedNumbers: [14, 21, 53, 71],
  mostDrawnNumbers: [23, 18, 34, 37, 45, 51, 73, 80],
  leastDrawnNumbers: [1, 28, 39, 41, 49, 66, 69, 79],
  screenSize: null,
  categoryConfig: null,
  newDrawnCounter: false,
  translations: null,
  timeZone: null,
  isResultsDataValid: false,
  categoryData: null,
  tournamentData: null,
  /**
   * Retail
   */
  events: false,
  bettingOffer: null,
  mainEvents: null,
  isVisualizationDrawEnabled: false,
  intervalTimeForBettingEvents: 10000,
  resultsSpecials: null,
  retailNextEvent: null,
  transitionBetweenComponentDuration: 7000,
  activeCounter: false,
  drawInProgress: false,
  dontHaveInternetTime: null,
  retailDrawEvent: null,
  prepareRetailDrawEvent: null,
  appAvailable: true,
  connectionError: false,
  fetchErrorDraw: false,
  fetchErrorOthers: false,
  queryParams: null,
};
