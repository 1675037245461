import EventBus from '@/store/EventBus';

const workerPostMessage = (title, data) => {
  window.lotteryWorker.postMessage({
    title,
    data,
  });
};

let conterActive = false;
const workerOnMessage = () => {
  window.lotteryWorker.onmessage = (event) => {
    if (event.data.title === 'checkInternet') {
      EventBus.$emit('checkInternet', event.data.data);
    } else if (event.data.title === 'remainingTime') {
      EventBus.$emit('remainingTime', event.data.data);
      if (event.data.data === '00:00') {
        EventBus.$emit('beforeDraw');
        conterActive = false;
      } else if (!conterActive) {
        conterActive = true;
        EventBus.$emit('startCounter');
      }
    } else if (event.data.title === 'fetchOthers') {
      EventBus.$emit('fetchOthers');
    } else if (event.data.title === 'fetchOnlyOthers') {
      EventBus.$emit('fetchOnlyOthers');
    }
  };
};

export {
  workerPostMessage,
  workerOnMessage,
};
