import getData from '@/api';
import apiConfig from '@/api/apiConfig';
import types from '@/store/mutationTypes';
import { dataParser } from '@/utility';

export default {
  setActiveLoader: ({ commit }, payload) => {
    commit(types.SET_ACTIVE_LOADER, payload);
  },
  setScreenSize: ({ commit }, payload) => {
    commit(types.SET_SCREEN_SIZE, payload);
  },
  fetchStatistics: async ({ commit, state, getters }) => {
    let url = state.categoryConfig.statistics.api;
    if (getters.isRetail) {
      url = `${url}&channel=retail`;
    }
    let resposeData = null;
    try {
      const statistics = await getData(url, {
        'HTTP-X-SEVEN-COMPANY-UUID': state.config.tenantId,
        'SEVEN-LOCALE': state.config.locale,
      });
      if (statistics.data && Object.keys(statistics.data).length) {
        resposeData = statistics.data;
        commit(types.SET_STATISTICS, statistics.data);
      }
    } catch (e) {
      console.log('statistics', e);
    }
    return resposeData;
  },
  fetchResults: async ({ commit, state }) => {
    const url = state.categoryConfig.api;
    let resposeData = null;
    try {
      const results = await getData(url, {
        'HTTP-X-SEVEN-COMPANY-UUID': state.config.tenantId,
        'SEVEN-LOCALE': state.config.locale,
      });
      if (results.data && Object.keys(results.data).length && !('message' in results.data)) {
        resposeData = results.data;
        const parseData = { ...results.data, tournamentId: 1101 };
        commit(types.SET_RESULTS, dataParser(parseData));
        commit(types.SET_IS_RESULTS_DATA_VALID, true);
      } else {
        commit(types.SET_IS_RESULTS_DATA_VALID, false);
      }
    } catch (e) {
      console.log('results', e);
      commit(types.SET_IS_RESULTS_DATA_VALID, false);
    }
    if (!resposeData) {
      commit(types.SET_APP_STARTED);
    }
    return resposeData;
  },
  fetchResultsAndStatistics: async ({ commit, dispatch }) => {
    const config = apiConfig('1101');
    commit(types.SET_CATEGORY_CONFIG, config);
    // eslint-disable-next-line no-unused-vars
    const statistics = await dispatch('fetchStatistics');
    // eslint-disable-next-line no-unused-vars
    const results = await dispatch('fetchResults');
    dispatch('setActiveLoader', false);
    return true;
  },
  setDrawnResults: ({ commit }, payload) => {
    commit(types.SET_DRAWN_RESULTS, payload);
  },
  updateDrawnResults: ({ commit }, payload) => {
    if (payload) {
      commit(types.UPDATE_DRAWN_RESULTS, payload);
    }
  },
  setNewDrawnCounter: ({ commit }, payload) => {
    commit(types.SET_NEW_DRAWN_COUNTER, payload);
  },
  fetchUpcomingEvents: async ({ commit, state }, payload) => {
    let url = process.env.VUE_APP_ENV_UPCOMING;
    url = payload ? `${url}?type=${payload}` : url;
    let data = {};
    try {
      data = (await getData(url, {
        'HTTP-X-SEVEN-COMPANY-UUID': state.config.tenantId,
        'SEVEN-LOCALE': state.config.locale,
      })).data;
      if (!payload) {
        commit(types.ADD_EVENTS, data);
        commit(types.SET_FETCH_EVENTS_ERROR, {
          key: 'Draw',
          value: false,
        });
        commit(types.SET_FETCH_EVENTS_ERROR, {
          key: 'Others',
          value: false,
        });
      } else {
        const type = payload === 'draws' ? 'ADD_MAIN_EVENTS' : 'ADD_OTHERS_BETTING_OFFER';
        commit(types[type], data);
        commit(types.SET_FETCH_EVENTS_ERROR, {
          key: type === 'ADD_MAIN_EVENTS' ? 'Draw' : 'Others',
          value: false,
        });
      }
      if ((!payload || payload === 'draws') && !state.retailDrawEvent && data.isVisualizationDrawEnabled) {
        commit(types.SET_RETAIL_DRAW_EVENT, data.events[0]);
      }
      commit(types.SET_VISUALIZATION_DRAW_ENABLED, data.isVisualizationDrawEnabled);
    } catch (e) {
      commit(types.SET_FETCH_EVENTS_ERROR, {
        key: 'Draw',
        value: true,
      });
      commit(types.SET_FETCH_EVENTS_ERROR, {
        key: 'Others',
        value: true,
      });
    }
    return data.isVisualizationDrawEnabled;
  },
  setActiveCounter: ({ commit }, payload) => {
    commit(types.SET_ACTIVE_COUNTER, payload);
  },
  setDrawInProgress: ({ commit }, payload) => {
    commit(types.SET_DRAWN_IN_PROGRESS, payload);
  },
  setDontHaveInternetTime: ({ commit }, payload) => {
    commit(types.SET_DONT_HAVE_INTERNET_TIME, payload);
  },
  setAppAvailable: ({ commit }, payload) => {
    commit(types.SET_APP_AVAILABLE, payload);
  },
  setConnectionError: ({ commit }, payload) => {
    commit(types.SET_CONNECTION_ERROR, payload);
  },
  setVisualizationDrawEnabled: ({ commit }, payload) => {
    commit(types.SET_VISUALIZATION_DRAW_ENABLED, payload);
  },
};
