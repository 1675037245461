const browserTimeout = 2000;
const addIntoLastTime = 10000;
const start = 5000;
let lastBrowserTime = new Date().getTime();

const wakeApp = (firstStart = false) => {
  setTimeout(() => {
    // console.log('wakeApp setInterval', new Date());
    const currentTime = new Date().getTime();
    const firstStartValue = firstStart ? start : 0;
    const lastTime = lastBrowserTime + browserTimeout + addIntoLastTime + firstStartValue;
    if (currentTime > lastTime) {
      window.location.reload();
    }
    lastBrowserTime = currentTime;
    wakeApp();
  }, browserTimeout);
};

export default wakeApp;
