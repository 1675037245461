import replace from 'lodash/replace';
import { floor } from 'lodash';

export default (odd) => {
  const calc = odd / 1000;
  let value;
  if (calc >= 1) {
    value = `${replace(floor(calc, 1).toString(), '.', ',')}K`;
  } else {
    const oddArray = odd.toString().split('.');
    let newOdd;
    if (oddArray.length > 1) {
      newOdd = odd.toFixed(2);
    } else {
      newOdd = oddArray[0].length < 3 ? odd.toFixed(1) : odd;
    }
    value = replace(newOdd, '.', ',');
  }
  return value;
};
