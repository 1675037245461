const getParams = async () => {
  const params = new URLSearchParams(window.location.search);
  const layout = params.get('layout') || 'BasicLayout';
  const platform = params.get('platform');
  const tenantId = params.get('tenantId');
  const productId = params.get('productId');
  const locale = params.get('locale') || 'en';
  const application = params.get('application');
  const integrationType = params.get('integrationType');
  const productName = 'NumbersBetting';
  const timeZone = params.get('timeZone') || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const windowTitle = params.get('windowTitle');
  const configData = {
    platform,
    tenantId,
    productId,
    productName,
    locale,
    application,
    integrationType,
    timeZone,
    windowTitle,
  };
  return {
    configData,
    layout,
    windowTitle,
  };
};

export default getParams;
