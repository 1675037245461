import { ConfigurationService, registry } from '@nsftx/games-config';

const getConfigService = async (local) => {
  // eslint-disable-next-line func-names
  registry.httpAdapters.LotteryHttpAdapter.prototype.getEndpoint = function () {
    switch (this.options.environment) {
      case 'production':
        return 'https://games-lottery.de.nsoftcdn.com';
      default:
        return 'https://staging-nbg.nsoft.com:7443';
    }
  };
  let config;
  try {
    const configServiceObject = {
      clientAdapters: [
        {
          name: 'UrlAdapter',
          options: local,
        },
      ],
      environment: process.env.VUE_APP_ENV === 'production' ? 'production' : 'staging',
      productName: 'NumbersBetting',
    };
    const dataConfig = local.windowTitle
      ? { ...configServiceObject, ...local } : configServiceObject;
    const configService = await new ConfigurationService(dataConfig);
    config = await configService.getConfiguration();
    if (!config) {
      console.log('Please try later no config data, config ERROR');
    }
  } catch (e) {
    console.log('Please try later no config data, try ERROR');
  }
  return config;
};

export default getConfigService;
