import Vue from 'vue';
import { initDatGui } from '@/utility/datGui';
import getConfig from '@/utility/getConfig';
import { workerPostMessage, workerOnMessage } from '@/utility/workerMessage';
import getParams from '@/utility/getParams';
import getNarConfig from '@/utility/getNarConfig';
import App from './App';
import router from './router';
import store from './store';
import i18n from './i18n';

window.lotteryWorker = null;

(async () => {
  try {
    store.dispatch('setScreenSize', window.innerWidth);
    const params = await getParams();
    let narConfig;
    if (params.windowTitle) {
      document.title = params.windowTitle;
      narConfig = await getNarConfig(params);
      store.state.queryParams = narConfig;
    }
    const config = await getConfig(params, narConfig);
    if (config) {
      config.locale = narConfig ? config.language : config.locale;
      store.commit('SET_CONFIG', config);
      store.state.translations = (await i18n(config.locale)).default;
      if (process.env.VUE_APP_ENV !== 'production') {
        initDatGui(store.state.translations, config.locale);
      }
    }
    new Vue({
      router,
      store,
      mounted() {
        if (!window.lotteryWorker && this.$store.getters.layout === 'RetailLayout') {
          if (!localStorage.getItem('reload') && !narConfig) {
            localStorage.setItem('reload', 'true');
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } else {
            localStorage.removeItem('reload');
            window.lotteryWorker = new Worker('/retailWorker.js');
            workerOnMessage(store);
            workerPostMessage('url', {
              url: `${process.env.VUE_APP_API_BASEURL}/api/status`,
              header: config.tenantId,
            });
          }
        }
      },
      beforeDestroy() {
        if (window.lotteryWorker && this.$store.getters.layout === 'RetailLayout') {
          window.lotteryWorker.terminate();
        }
      },
      render: (h) => h(App),
    }).$mount('#app');
  } catch (e) {
    console.log('some error');
  }
})();
