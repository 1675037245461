<template>
  <div id="app">
    <div class="layout-wrapper"
         v-if="appStarted">
      <component :is="selectLayout"></component>
    </div>
    <AppLoader v-if="activeLoader && !appStarted"/>
  </div>
</template>

<script>
import wakeApp from '@/utility/wakeApp';
import { mapActions, mapGetters } from 'vuex';
import AppLoader from '@/components/AppLoader';

export default {
  name: 'App',
  data() {
    return {
      selectLayout: '',
    };
  },
  components: {
    AppLoader,
    BasicLayout: () => import('./layouts/BasicLayout'),
    RetailLayout: () => import('./layouts/RetailLayout'),
  },
  computed: {
    ...mapGetters([
      'appStarted',
      'layout',
      'activeLoader',
    ]),
  },
  methods: {
    ...mapActions([
      'setScreenSize',
      'fetchResultsAndStatistics',
    ]),
  },
  beforeMount() {
    // console.log('beforeMount', new Date());
    this.selectLayout = this.layout ? this.layout : 'BasicLayout';
    window.addEventListener('resize', () => {
      this.setScreenSize(window.innerWidth);
    });
    this.fetchResultsAndStatistics();
  },
  watch: {
    appStarted(newValue) {
      if (newValue) {
        wakeApp(true);
      }
    },
  },
};
</script>

<style lang="scss">
*,
*:before,
*:after {
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 12px;
}
body {
  font-family: 'Rubik', sans-serif;
}
html,
body,
#app {
  height: 100%;
  width: 100%;
}
#app {
  position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}
.save-row {
  display: none;
}
</style>
