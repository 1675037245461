import axios from 'axios';

const getNarConfig = async (params) => {
  try {
    const { windowTitle } = params;
    // const urlAppConfig = 'https://jsonblob.com/api/jsonBlob/1065639012555046912';
    const urlAppConfig = 'http://localhost:8082/get_app_config';
    const urlDevieConfig = 'http://localhost:8081';
    // const urlDevieConfig = 'https://jsonblob.com/api/jsonBlob/1065639772474851328';
    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const appReq = await axios.post(urlAppConfig, {
      windowTitle,
    }, headers);
    // const appReq = await axios.get(urlAppConfig, headers);
    const deviceReq = await axios.get(urlDevieConfig, headers);
    const response = await Promise.all([appReq, deviceReq]);
    const data = { ...response[0].data, ...response[1].data };
    return {
      // tenantId: 'b99752b3-443c-4c80-b559-945a95c4b805',
      tenantId: data.betshop.companyUuid,
      application: data.device.type,
      timeZone: data.timezone,
      // productId: 'b4a92bf5-e159-4a98-bebb-2ce956884cad',
      productId: data.cpvUuids[0],
      platform: 'seven',
      layout: 'RetailLayout',
    };
  } catch {
    return null;
  }
};

export default getNarConfig;
