import dat from 'dat.gui';

import {
  defaultTo,
  each,
  forOwn,
  keys,
  toLower,
} from 'lodash';

let datGui;

const downloadFile = (fileName, content) => {
  const element = document.createElement('a');

  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`);
  element.setAttribute('download', fileName);
  element.style.display = 'none';

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const addDatGuiFolder = (folderName) => {
  const guiFolder = datGui.addFolder(folderName);
  return guiFolder;
};

export const addDatGuiFolderWithKeys = (folderName, observable) => {
  if (datGui) {
    const folder = addDatGuiFolder(folderName);

    datGui.remember(observable);
    each(observable, (value, key) => {
      folder.add(observable, key);
    });
  }
};

export const initDatGui = (observable, locale) => {
  datGui = new dat.GUI({
    name: 'LotteryDatGui',
    width: 600,
    closeOnTop: true,
    hideable: true,
  });

  datGui.hide();

  const ui = {
    save: () => {
      const data = datGui.getSaveObject();
      const folders = keys(data.folders);
      const language = defaultTo(ui.language, 'en');

      each(data.remembered.Default, (value, key) => {
        const fileName = `${toLower(folders[key])}.${language}.js`;
        const fileContent = [];

        fileContent.push('export default {\n');

        forOwn(value, (valueData, valueKey) => {
          const encodedValueData = valueData.replace(/'/g, '\\\'');
          fileContent.push(`  ${valueKey}: '${encodedValueData}',\n`);
        });

        fileContent.push('};\n');

        downloadFile(fileName, fileContent.join(''));
      });
    },
    language: locale,
  };

  datGui.add(ui, 'save');
  datGui.add(ui, 'language');

  addDatGuiFolderWithKeys('Common', observable);
};
