import Vue from 'vue';
import { workerPostMessage } from '@/utility/workerMessage';
import { filter } from 'lodash';
import types from './mutationTypes';

export default {
  [types.SET_CONFIG](state, payload) {
    state.config = payload;
  },
  [types.SET_APP_STARTED](state) {
    state.appStarted = true;
  },
  [types.SET_RESULTS](state, payload) {
    state.results = payload.results;
    state.eventTime = payload.eventTime;
    state.nextEventTime = payload.nextEventTime;
    state.resultsSpecials = {
      drawSumEvenOdd: payload.drawSumEvenOdd,
      drawSumRange: payload.drawSumRange,
      drawSumTotal: payload.drawSumTotal,
    };
    Vue.set(state, 'categoryData', payload.categoryData);
    Vue.set(state, 'tournamentData', payload.tournamentData);
    state.appStarted = true;
  },
  [types.SET_ACTIVE_LOADER](state, payload) {
    state.activeLoader = payload;
  },
  [types.SET_SCREEN_SIZE](state, payload) {
    state.screenSize = payload;
  },
  [types.SET_STATISTICS](state, payload) {
    Vue.set(state, 'mostPlayedNumbers', payload.mostPlayedNumbers);
    Vue.set(state, 'leastPlayedNumbers', payload.leastPlayedNumbers);
    Vue.set(state, 'mostDrawnNumbers', payload.mostDrawnNumbers);
    Vue.set(state, 'leastDrawnNumbers', payload.leastDrawnNumbers);
  },
  [types.SET_CATEGORY_CONFIG](state, payload) {
    state.categoryConfig = payload;
  },
  [types.SET_DRAWN_RESULTS](state, payload) {
    Vue.set(state, 'drawnResults', payload);
  },
  [types.UPDATE_DRAWN_RESULTS](state, payload) {
    Vue.set(state.drawnResults, state.drawnResults.length, payload);
  },
  [types.SET_NEW_DRAWN_COUNTER](state, payload) {
    state.newDrawnCounter = payload;
  },
  [types.SET_IS_RESULTS_DATA_VALID](state, payload) {
    state.isResultsDataValid = payload;
  },
  [types.ADD_EVENTS](state, payload) {
    Vue.set(state, 'events', payload.events);
    state.isVisualizationDrawEnabled = payload.isVisualizationDrawEnabled;
    const others = filter(state.events,
      (event) => event.tournament.id !== 49024);
    const main = filter(state.events, (event) => event.tournament.id === 49024);
    Vue.set(state, 'bettingOffer', others);
    Vue.set(state, 'mainEvents', main);
    Vue.set(state, 'retailNextEvent', main[0]);
    workerPostMessage('startCounter', {
      drawTime: Object.keys(state.retailNextEvent).length
        ? state.retailNextEvent.eventStartTimeUTC - 60000 : null,
      otherNextTime: others[0].eventStartTimeUTC - 60000,
    });
  },
  [types.SET_ACTIVE_COUNTER](state, payload) {
    state.activeCounter = payload;
  },
  [types.SET_DRAWN_IN_PROGRESS](state, payload) {
    state.drawInProgress = payload;
  },
  [types.ADD_MAIN_EVENTS](state, payload) {
    state.isVisualizationDrawEnabled = payload.isVisualizationDrawEnabled;
    if (payload.events.length) {
      Vue.set(state, 'mainEvents', payload.events);
      Vue.set(state, 'retailNextEvent', payload.events[0]);
      workerPostMessage('updateEventsTime', {
        drawTime: payload.events[0].eventStartTimeUTC - 60000,
        otherNextTime: null,
      });
    }
  },
  [types.ADD_OTHERS_BETTING_OFFER](state, payload) {
    state.isVisualizationDrawEnabled = payload.isVisualizationDrawEnabled;
    if (payload.events.length) {
      Vue.set(state, 'bettingOffer', payload.events);
      workerPostMessage('updateEventsTime', {
        drawTime: null,
        otherNextTime: payload.events[0].eventStartTimeUTC - 60000,
      });
    }
  },
  [types.SET_DONT_HAVE_INTERNET_TIME](state, payload) {
    state.dontHaveInternetTime = payload;
  },
  [types.SET_APP_AVAILABLE](state, payload) {
    state.setAppAvailable = payload;
  },
  [types.SET_RETAIL_DRAW_EVENT](state, payload) {
    Vue.set(state, 'retailDrawEvent', payload);
  },
  [types.PREPARE_RETAIL_DRAW_EVENT](state, payload) {
    Vue.set(state, 'prepareRetailDrawEvent', payload);
  },
  [types.SET_CONNECTION_ERROR](state, payload) {
    state.connectionError = payload;
  },
  [types.SET_VISUALIZATION_DRAW_ENABLED](state, payload) {
    state.isVisualizationDrawEnabled = payload;
  },
  [types.RESET_DRAW_NEXT_EVENT](state, payload) {
    state.isVisualizationDrawEnabled = payload;
    Vue.set(state, 'prepareRetailDrawEvent', null);
    Vue.set(state, 'retailDrawEvent', null);
    Vue.set(state, 'retailNextEvent', null);
  },
  [types.SET_FETCH_EVENTS_ERROR](state, payload) {
    const prop = `fetchError${payload.key}`;
    Vue.set(state, prop, payload.value);
  },
};
